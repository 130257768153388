body,
h4,
h5,
h6,
p,
.btn,
a,
small {
      font-family: "Montserrat", sans-serif;
}

h1,
h2,
h3,
.lead {
      font-family: "Archivo Narrow", sans-serif;
}

.lead {
      color: rgb(4, 108, 172);
}

h1.main {
      letter-spacing: 0.1em;
}

.App-logo {
      height: 200px;
      margin: 0 auto;
}

.app-logo-container {
      display: flex;
      justify-content: center;
      align-items: center;
}

.App {
      display: flex;
      flex-direction: column;
      min-height: 100vh;
}

.App-header {
      background: linear-gradient(rgba(34, 108, 172, 0.45), rgba(64, 150, 225, 0.502)), url("/images/connectwork.png");
      padding: 20px;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
}

nav {
      display: flex;
      justify-content: space-around;
      align-items: center;
}

footer {
      background: linear-gradient(rgba(34, 108, 172, 0.45), rgba(64, 150, 225, 0.502)), url("/images/connectwork.png");
      flex: 0 0 auto;
      padding: 20px;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: auto;
}

.triee {
      color: rgb(34, 108, 172);
}

.table {
      width: 80%;
      margin: 0 auto;
}

.bg-reseau {
      background-color: rgb(34, 108, 172);
}

.bg-accent {
      background-color: rgb(255, 152, 42);
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before,
.btn-reseau {
      background-color: rgb(50, 145, 205);
}

.btn-reseau-reverse {
      background-color: rgb(255, 255, 255);
      border: 2px solid rgb(50, 145, 205);
}

.text-info,
.text-reseau,
a,
.btn-link {
      color: rgb(4, 108, 172);
}

.btn-link:hover,
.invalid-feedback {
      color: rgb(255, 152, 42);
}

.custom-select.is-invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.was-validated .form-control:invalid {
      border-color: rgb(255, 152, 42);
}

.custom-select.is-invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.was-validated .form-control:invalid:focus {
      border-color: rgb(255, 152, 42);
      box-shadow: 0 0 0 0.2rem rgba(255, 152, 42, 0.194);
}

.btn-reseau {
      color: white;
}

.btn-outline-reseau {
      background-color: white;
      border: rgb(4, 59, 118) 1px solid;
      color: rgb(4, 59, 118);
      transition: background-color 500ms, color 500ms;
}

.btn-outline-reseau:hover {
      background-color: rgb(4, 59, 118);
      border: rgb(4, 59, 118) 1px solid;
      color: white;
}

.alert-info,
.alert-reseau {
      color: white;
      background-color: rgb(4, 108, 172);
      border-color: rgb(198, 230, 250);
}

.liste-etablissements {
      padding: 1rem;
      border: 1px solid lightgray;
      border-radius: 5px;
      display: flex;
      flex-wrap: wrap;
      font-size: 0.85rem;
}

.etablissements-btn {
      display: flex;
      justify-content: center;
      align-items: center;
}

.pills {
      border-radius: 25px;
      padding: 0.75rem 1rem;
      background-color: rgb(4, 108, 172);
      border: 1px solid white;
      color: white;

      & .remove-btn {
            cursor: pointer;
            font-size: 1rem;

            &:hover {
                  color: color-mix(in oklab, rgb(4, 108, 172) 40%, white 60%);
            }
      }
}


@media (min-width: 768px) {
      .modal-xl {
            width: 90%;
            max-width: 1200px;
      }
}

.activity-row {
      border: transparent 2px solid;
      transition: all 0.5s;
}

.activity-row:hover {
      border: rgb(4, 59, 118) 2px solid;
      box-shadow: 5px 5px 5px 5px rgba(4, 59, 118, 0.4);
}

.clickable-header {
      color: rgb(4, 108, 172);
      text-decoration: underline;
      cursor: pointer;
      font-weight: bolder;
}